<template>
  <div class="ranking">
    <!-- <schoolHead /> -->
    <div class="ranking-nav">
      <p @click="goBack"><span>返回</span></p>
    </div>
    <div class="ranking-list leaderboard">
      <div class="ranking-list-cont">
        <div class="ranking-list-cont-val left">
          <p>{{ rankList[2].rankVal }}</p>
        </div>
        <div class="ranking-right-list">
          <div class="ranking-right-list-tit">
            <p class="ranking-right-list-tit-n">积分数据</p>
            <div class="ranking-right-list-tit-c">
              <p
                v-for="item in cityList"
                :key="item.val"
                :class="item.val == curLeaderboardCity ? 'active' : ''"
                @click="changeLeaderboardCity(item)"
              >
                {{ item.name }}
              </p>
            </div>
          </div>
          <div class="ran-list-thead">
            <ul>
              <li>序号</li>
              <li>园所名称</li>
              <li>园所人数</li>
              <li>活跃积分</li>
            </ul>
          </div>
          <div
            class="ran-list-tbody"
            v-if="leaderboardList.rankingList"
            v-loading="loading"
          >
            <div class="my-ranking">
              <ul>
                <li>{{ leaderboardList.schoolRankingNum || "--" }}</li>
                <li>
                  {{
                    (leaderboardList.schoolRankingInfo &&
                      leaderboardList.schoolRankingInfo.schoolName) ||
                    $store.state.schoolInfo.atteSchool.schoolName
                  }}
                </li>
                <li>
                  {{
                    (leaderboardList.schoolRankingInfo &&
                      leaderboardList.schoolRankingInfo.validStudentCount) ||
                    "--"
                  }}
                </li>
                <li>
                  {{
                    (leaderboardList.schoolRankingInfo &&
                      leaderboardList.schoolRankingInfo.integralTotal) ||
                    "--"
                  }}
                </li>
              </ul>
            </div>
            <div v-if="leaderboardList.rankingList[0].schoolName !== '--'">
              <ul
                v-for="(item, index) in leaderboardList.rankingList"
                :key="index"
              >
                <li>{{ index + 1 }}</li>
                <li :title="item.schoolName">{{ item.schoolName }}</li>
                <li>{{ item.validStudentCount || 0 }}</li>
                <li>{{ item.integralTotal || 0 }}</li>
              </ul>
            </div>
            <div v-else class="noData">
              <img src="../../assets/img/rank/Pic_Ph_Ks_Red.png" alt="" />
              <span>暂无数据</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="ranking-list learning">
      <div class="ranking-list-cont">
        <div class="ranking-right-list table-left">
          <div class="ranking-right-list-tit">
            <p class="ranking-right-list-tit-n">学习数据</p>
            <div class="ranking-right-list-tit-c">
              <p
                v-for="item in cityList"
                :key="item.val"
                :class="item.val == curLearningCity ? 'active' : ''"
                @click="changeLearningCity(item)"
              >
                {{ item.name }}
              </p>
            </div>
          </div>
          <div class="ran-list-thead">
            <ul>
              <li>序号</li>
              <li>园所名称</li>
              <li>学习人数</li>
              <li>学习人次</li>
            </ul>
          </div>
          <div
            class="ran-list-tbody"
            v-if="learningList.rankingList"
            v-loading="loading"
          >
            <div class="my-ranking">
              <ul>
                <li>{{ learningList.schoolRankingNum || "--" }}</li>
                <li :title="learningList.schoolRankingInfo.schoolName">
                  {{
                    (learningList.schoolRankingInfo &&
                      learningList.schoolRankingInfo.schoolName) ||
                    $store.state.schoolInfo.atteSchool.schoolName
                  }}
                </li>
                <li>
                  {{
                    (learningList.schoolRankingInfo &&
                      learningList.schoolRankingInfo.studentStudyCountTotal) ||
                    "--"
                  }}
                </li>
                <li>
                  {{
                    (learningList.schoolRankingInfo &&
                      learningList.schoolRankingInfo.studyCountTotal) ||
                    "--"
                  }}
                </li>
              </ul>
            </div>
            <div v-if="learningList.rankingList[0].schoolName !== '--'">
              <ul
                v-for="(item, index) in learningList.rankingList"
                :key="index"
              >
                <li>{{ index + 1 }}</li>
                <li :title="item.schoolName">{{ item.schoolName }}</li>
                <li>{{ item.studentStudyCountTotal || 0 }}</li>
                <li>{{ item.studyCountTotal || 0 }}</li>
              </ul>
            </div>
            <div v-else class="noData">
              <img src="../../assets/img/rank/Pic_Ph_Ks_Blue.png" alt="" />
              <span>暂无数据</span>
            </div>
          </div>
        </div>
        <div class="ranking-list-cont-val right">
          <p>{{ rankList[0].rankVal }}</p>
        </div>
      </div>
    </div>
    <div class="ranking-list football">
      <div class="ranking-list-cont">
        <div class="ranking-list-cont-val left">
          <p>{{ rankList[1].rankVal }}</p>
        </div>
        <div class="ranking-right-list">
          <div class="ranking-right-list-tit">
            <p class="ranking-right-list-tit-n">足特数据</p>
            <div class="ranking-right-list-tit-c">
              <p
                v-for="item in cityList"
                :key="item.val"
                :class="item.val == curFootballCity ? 'active' : ''"
                @click="changeFootballCity(item)"
              >
                {{ item.name }}
              </p>
            </div>
          </div>
          <div class="ran-list-thead">
            <ul>
              <li>序号</li>
              <li>园所名称</li>
              <li>园所人数</li>
              <li>活跃积分</li>
            </ul>
          </div>
          <div
            class="ran-list-tbody"
            v-if="footballList.rankingList"
            v-loading="loading"
          >
            <div class="my-ranking">
              <ul>
                <li>{{ footballList.schoolRankingNum || "--" }}</li>
                <li :title="footballList.schoolRankingInfo.schoolName">
                  {{
                    (footballList.schoolRankingInfo &&
                      footballList.schoolRankingInfo.schoolName) ||
                    $store.state.schoolInfo.atteSchool.schoolName
                  }}
                </li>
                <li>
                  {{
                    (footballList.schoolRankingInfo &&
                      footballList.schoolRankingInfo.registerStudentCount) ||
                    "--"
                  }}
                </li>
                <li>
                  {{
                    (footballList.schoolRankingInfo &&
                      footballList.schoolRankingInfo.integralTotal) ||
                    "--"
                  }}
                </li>
              </ul>
            </div>
            <div v-if="footballList.rankingList[0].schoolName !== '--'">
              <ul
                v-for="(item, index) in footballList.rankingList"
                :key="index"
              >
                <li>{{ index + 1 }}</li>
                <li :title="item.schoolName">{{ item.schoolName }}</li>
                <li>
                  {{ item.registerStudentCount || 0 }}
                </li>
                <li>{{ item.integralTotal || 0 }}</li>
              </ul>
            </div>
            <div v-else class="noData">
              <img src="../../assets/img/rank/Pic_Ph_Ks_Red.png" alt="" />
              <span>暂无数据</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="ranking-list mvk">
      <div class="ranking-list-cont">
        <div class="ranking-right-list table-left">
          <div class="ranking-right-list-tit">
            <p class="ranking-right-list-tit-n">示范数据</p>
            <div class="ranking-right-list-tit-c">
              <p
                v-for="item in cityList"
                :key="item.val"
                :class="item.val == curMvkCity ? 'active' : ''"
                @click="changeMvkCity(item)"
              >
                {{ item.name }}
              </p>
            </div>
          </div>
          <div class="ran-list-thead">
            <ul>
              <li>序号</li>
              <li>园所名称</li>
              <li>园所人数</li>
              <li>活跃积分</li>
            </ul>
          </div>
          <div
            class="ran-list-tbody"
            v-if="mvkList.rankingList"
            v-loading="loading"
          >
            <div class="my-ranking">
              <ul>
                <li>{{ mvkList.schoolRankingNum || "--" }}</li>
                <li>
                  {{
                    (mvkList.schoolRankingInfo &&
                      mvkList.schoolRankingInfo.schoolName) ||
                    $store.state.schoolInfo.atteSchool.schoolName
                  }}
                </li>
                <li>
                  {{
                    (mvkList.schoolRankingInfo &&
                      mvkList.schoolRankingInfo.registerStudentCount) ||
                    "--"
                  }}
                </li>
                <li>
                  {{
                    (mvkList.schoolRankingInfo &&
                      mvkList.schoolRankingInfo.integralTotal) ||
                    "--"
                  }}
                </li>
              </ul>
            </div>
            <div v-if="mvkList.rankingList[0].schoolName !== '--'">
              <ul v-for="(item, index) in mvkList.rankingList" :key="index">
                <li>{{ index + 1 }}</li>
                <li :title="item.schoolName">{{ item.schoolName }}</li>
                <li>{{ item.registerStudentCount || 0 }}</li>
                <li>{{ item.integralTotal || 0 }}</li>
              </ul>
            </div>
            <div v-else class="noData">
              <img src="../../assets/img/rank/Pic_Ph_Ks_Blue.png" alt="" />
              <span>暂无数据</span>
            </div>
          </div>
        </div>
        <div class="ranking-list-cont-val right">
          <p>{{ rankList[3].rankVal }}</p>
        </div>
      </div>
    </div>
    <div class="ranking-list active">
      <div class="ranking-list-cont">
        <div class="ranking-list-cont-val left">
          <p>{{ rankList[4].rankVal }}</p>
        </div>
        <div class="ranking-right-list">
          <div class="ranking-right-list-tit">
            <p class="ranking-right-list-tit-n">活跃数据</p>
            <div class="ranking-right-list-tit-c">
              <p
                v-for="item in cityList"
                :key="item.val"
                :class="item.val == curActiveCity ? 'active' : ''"
                @click="changeActiveCity(item)"
              >
                {{ item.name }}
              </p>
            </div>
          </div>
          <div class="ran-list-thead">
            <ul>
              <li>序号</li>
              <li>园所名称</li>
              <li>园所人数</li>
              <li>活跃积分</li>
              <li>入驻家长</li>
              <li>参与次数</li>
            </ul>
          </div>
          <div
            class="ran-list-tbody"
            v-if="activeList.rankingList"
            v-loading="loading"
          >
            <div class="my-ranking">
              <ul>
                <li>{{ activeList.schoolRankingNum || "--" }}</li>
                <li>
                  {{
                    (activeList.schoolRankingInfo &&
                      activeList.schoolRankingInfo.schoolName) ||
                    $store.state.schoolInfo.atteSchool.schoolName
                  }}
                </li>
                <li>
                  {{
                    (activeList.schoolRankingInfo &&
                      activeList.schoolRankingInfo.registerStudentCount) ||
                    "--"
                  }}
                </li>
                <li>
                  {{
                    (activeList.schoolRankingInfo &&
                      activeList.schoolRankingInfo.integralTotal) ||
                    "--"
                  }}
                </li>
                <li>
                  {{
                    (activeList.schoolRankingInfo &&
                      activeList.schoolRankingInfo.parentCount) ||
                    "--"
                  }}
                </li>
                <li>
                  {{
                    (activeList.schoolRankingInfo &&
                      activeList.schoolRankingInfo.parentStudyCount) ||
                    "--"
                  }}
                </li>
              </ul>
            </div>
            <div v-if="activeList.rankingList[0].schoolName !== '--'">
              <ul v-for="(item, index) in activeList.rankingList" :key="index">
                <li>{{ index + 1 }}</li>
                <li :title="item.schoolName">{{ item.schoolName }}</li>
                <li>{{ item.registerStudentCount || 0 }}</li>
                <li>{{ item.integralTotal || 0 }}</li>
                <li>{{ item.parentCount || 0 }}</li>
                <li>{{ item.parentStudyCount || 0 }}</li>
              </ul>
            </div>
            <div v-else class="noData">
              <img src="../../assets/img/rank/Pic_Ph_Ks_Red.png" alt="" />
              <span>暂无数据</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      loading: false,
      rankList: [
        {
          id: 1,
          name: "学习数据",
          enName: "Learning List",
          rankName: "序号",
          rankVal: "",
          className: "learning",
          classNameActive: "learning-active",
          listName: "learningList",
          cityName: "curLearningCity",
        },
        {
          id: 2,
          name: "足特数据",
          enName: "Football Ranking",
          rankName: "序号",
          rankVal: "",
          className: "football",
          classNameActive: "football-active",
          listName: "footballList",
          cityName: "curFootballCity",
        },
        {
          id: 0,
          name: "积分数据",
          enName: "Leaderboard",
          rankName: "序号",
          rankVal: "",
          className: "leaderboard",
          classNameActive: "leaderboard-active",
          listName: "leaderboardList",
          cityName: "curLeaderboardCity",
        },
        {
          id: 3,
          name: "示范数据",
          enName: "MVK Ranking",
          rankName: "序号",
          rankVal: "",
          className: "mvk",
          classNameActive: "mvk-active",
          listName: "mvkList",
          cityName: "curMvkCity",
        },
        {
          id: 4,
          name: "活跃数据",
          enName: "Active List",
          rankName: "序号",
          rankVal: "",
          className: "activeList",
          classNameActive: "activeList-active",
          listName: "activeList",
          cityName: "curActiveCity",
        },
      ],
      curRankId: 0,
      schoolId: "",
      cityList: [
        {
          name: "省",
          val: 1,
        },
        {
          name: "市",
          val: 2,
        },
      ],
      curLearningCity: 1,
      curFootballCity: 1,
      curLeaderboardCity: 1,
      curMvkCity: 1,
      curActiveCity: 1,
      provinceName: "",
      cityName: "",
      // rankTable:{},
      learningList: {}, //学习榜
      footballList: {}, //足特排行
      leaderboardList: {}, //积分榜
      mvkList: {}, //示范排行
      activeList: {}, //活跃排行
      // tbody1: [],
      // tbody2: [],
      // tbody3: [],
      // tbody4: [],
      isFootballSchool: false, //是否为足特园
      isDemonstrationSchool: false, //是否为示范园
    };
  },
  mounted() {
    this.schoolId = this.$store.state.userInfo.schoolId;
    this.provinceName = this.$store.state.schoolInfo.atteSchool.province;
    this.cityName = this.$store.state.schoolInfo.atteSchool.trueCity;
    this.getSchoolInfo();

    //积分
    this.getRankList(0, this.curLeaderboardCity).then((res) => {
      if (res.flag) {
        this.formateData(this.leaderboardList, res, 0);
      }
    });
    //学习
    this.getRankList(1, this.curLearningCity).then((res) => {
      if (res.flag) {
        this.formateData(this.learningList, res, 1);
      }
    });
    //足特
    this.getRankList(2, this.curFootballCity).then((res) => {
      if (res.flag) {
        this.formateData(this.footballList, res, 2);
      }
    });
    //示范
    this.getRankList(3, this.curMvkCity).then((res) => {
      if (res.flag) {
        this.formateData(this.mvkList, res, 3);
      }
    });
    //活跃
    this.getRankList(4, this.curActiveCity).then((res) => {
      if (res.flag) {
        this.formateData(this.activeList, res, 4);
      }
    });
  },
  methods: {
    //切换学习榜省市
    changeLearningCity(data) {
      this.curLearningCity = data.val;
      this.getRankList(1, this.curLearningCity).then((res) => {
        if (res.flag) {
          this.formateData(this.learningList, res, 1);
        }
      });
    },
    //切换足特榜省市
    changeFootballCity(data) {
      this.curFootballCity = data.val;
      this.getRankList(2, this.curFootballCity).then((res) => {
        if (res.flag) {
          this.formateData(this.footballList, res, 2);
        }
      });
    },
    //切换示范榜省市
    changeMvkCity(data) {
      this.curMvkCity = data.val;
      this.getRankList(3, this.curMvkCity).then((res) => {
        if (res.flag) {
          this.formateData(this.mvkList, res, 3);
        }
      });
    },
    //切换活跃榜省市
    changeActiveCity(data) {
      this.curActiveCity = data.val;
      this.getRankList(4, this.curActiveCity).then((res) => {
        if (res.flag) {
          this.formateData(this.activeList, res, 4);
        }
      });
    },

    //切换积分榜省市
    changeLeaderboardCity(data) {
      this.curLeaderboardCity = data.val;
      this.getRankList(0, this.curLeaderboardCity).then((res) => {
        if (res.flag) {
          this.formateData(this.leaderboardList, res, 0);
        }
      });
    },
    formateData(data, res, curRankId) {
      // activeList
      // data = res.data;
      this.$set(data, "schoolRankingNum", res.data.schoolRankingNum);
      this.$set(data, "schoolRankingInfo", res.data.schoolRankingInfo);
      if (res.data.rankingList.length > 10) {
        res.data.rankingList = res.data.rankingList.splice(0, 10);
      } else {
        let len = res.data.rankingList.length;
        for (let i = len; i < 10; i++) {
          res.data.rankingList.push({
            schoolName: "--",
            registerStudentCount: "--",
            integralTotal: "--",
            studentStudyCountTotal: "--",
            studyCountTotal: "--",
            parentStudyCount: "--",
            parentCount: "--",
          });
        }
      }
      this.$set(data, "rankingList", res.data.rankingList);
      this.rankList.forEach((item) => {
        if (item.id == curRankId) {
          item.rankVal = res.data.schoolRankingNum;
        }
      });
    },
    async getRankList(rankingType, cityType) {
      let params = {
        schoolId: this.schoolId,
        rankingType: rankingType,
      };
      if (cityType == 1) {
        params.province = this.provinceName;
      } else {
        params.province = this.provinceName;
        params.city = this.cityName;
      }
      // this.loading = true;
      let data = await this.api.school.findTopByType(params);
      // this.loading = false;
      return data;
    },
    //获取学校信息  示范园 足特园
    getSchoolInfo() {
      //学校信息
      this.api.admin.atteSchool(this.schoolId).then((res) => {
        //`audit_state` enum('0','1','2','3') NOT NULL DEFAULT '0' COMMENT '足球特色申请:0=未提交申请,1=待审核,2=已审核通过,3=未通过',
        this.isFootballSchool = res.data.auditState == 2;
        //level 0:普通 1:团购  2:VIP 3:内部账号 4:示范园 5:公益园  6:第二批示范园
        this.isDemonstrationSchool = res.data.level == 6 || res.data.level == 4;
      });
    },
    //退出
    goBack() {
      this.$router.go(-1);
    },
  },
  // components: {
  //   schoolHead,
  // },
  watch: {
    demoTtile(val) {
      //普通的watch监听
      console.log("a: " + val);
    },
  },
};
</script>

<style lang="scss" scoped>
.ranking-nav {
  height: 68px;
  line-height: 68px;
  background: #ba3037;
  > p {
    margin: 0 auto;
    width: 1434px;
    font-size: 20px;
    font-weight: 400;
    color: #ffffff;
    cursor: pointer;
    background: url("../../assets/img/rank/Icon_Ph_back.png") no-repeat left
      center;
    background-size: 9px 15px;
    padding-left: 17px;
  }
}
.ranking-list {
  padding-top: 54px;
  height: 606px;
  width: 100%;
  .ranking-list-cont {
    margin: 0 auto;
    width: 1350px;
    @include flex(row, space-between, top);
    .ranking-list-cont-val {
      margin-top: 60px;
      // width:601px;
      height: 46px;

      > p {
        font-size: 64px;
        font-weight: bold;
        color: #fcdaaf;
      }
    }
    .left {
      padding: 222px 54px 0 437px;
      width: 148px;
      text-align: right;
    }
    .right {
      padding: 222px 0 0 65px;
      width: 536px;
      text-align: left;
    }
    .ranking-right-list {
      color: #333333;
      width: 601px;
      height: 558px;
      box-shadow: 0px 2px 13px 0px rgba(23, 4, 4, 0.61);
      border-radius: 2px;

      .ranking-right-list-tit {
        height: 51px;
        line-height: 51px;
        // border-bottom:1px solid #A73A3F;
        // border-radius: 2px;
        background: #ffffff;
        @include flex(row, space-between, center);

        .ranking-right-list-tit-n {
          padding-left: 29px;
          font-size: 18px;
          font-family: Microsoft YaHei;
          font-weight: bold;
          opacity: 0.9;
        }
        .ranking-right-list-tit-c {
          margin-right: 24px;
          width: 96px;
          height: 22px;
          line-height: 22px;
          border: 1px solid #f4969b;
          border-radius: 2px 0px 0px 2px;
          @include flex(row, flex-start, center);

          > p {
            width: 48px;
            height: 100%;
            text-align: center;
            font-size: 16px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #f4969b;
            opacity: 0.9;
            cursor: pointer;
          }
          > p.active {
            background: #f4969b;
            border-radius: 0px 2px 2px 0px;
            font-weight: bold;
            color: #ffffff;
            opacity: 0.9;
          }
        }
      }
      .ran-list-thead {
        ul {
          width: 100%;
          height: 50px;
          display: flex;
          flex-direction: row;
          flex-wrap: nowrap;
          justify-content: space-between;
          font-size: 16px;
          font-weight: bold;
          background: #f4969b;
          border-radius: 2px;
          color: #ffffff;
          opacity: 0.9;
          li {
            flex: 1;
            line-height: 50px;
            text-align: center;
          }
        }
      }
      .ran-list-tbody {
        position: relative;
        background: #ffffff;
        padding-top: 42px;
        ul {
          font-size: 16px;
          width: 100%;
          height: 42px;
          display: flex;
          flex-direction: row;
          flex-wrap: nowrap;
          li {
            flex: 1;
            line-height: 42px;
            text-align: center;
            font-size: 14px;
            color: #333333;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
          // li:nth-child(2){

          // }
        }
        ul:nth-child(even) {
          background: #fff1f2;
        }
        .my-ranking {
          position: absolute;
          left: -14px;
          top: -10px;
          z-index: 1;
          width: 628px;
          height: 62px;
          line-height: 62px;
          background: url(../../assets/img/rank/Pic_Bg_Bd_Red.png) no-repeat;
          background-size: 100% 100%;
          ul {
            width: 600px;
            height: 100%;
            line-height: 62px;
            background: none;
            margin: 0 auto;
            li {
              font-size: 16px !important;
              font-weight: bold;
              color: #ffe08b !important;
              opacity: 0.9;
              line-height: 62px;
            }
          }
        }
        .noData {
          text-align: center;

          > img {
            margin-top: 146px;
          }
          > span {
            margin-top: 24px;
            padding-bottom: 146px;
            display: block;
            font-size: 16px;
            color: #b67e81;
          }
        }
      }
    }
    .table-left {
      .ranking-right-list-tit {
        .ranking-right-list-tit-c {
          border: 1px solid #a2b7ff;

          > p {
            width: 48px;
            height: 100%;
            text-align: center;
            font-size: 16px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #a2b7ff;
            opacity: 0.9;
            cursor: pointer;
          }
          > p.active {
            background: #a2b7ff;
            border-radius: 0px 2px 2px 0px;
            font-weight: bold;
            color: #ffffff;
            opacity: 0.9;
          }
        }
      }
      .ran-list-thead {
        ul {
          background: #a2b7ff;
        }
      }
      .ran-list-tbody {
        ul:nth-child(even) {
          background: #f1f4ff;
        }
        .my-ranking {
          background: url(../../assets/img/rank/Pic_Bg_Bd_Blue.png) no-repeat;
          background-size: 100% 100%;
          ul {
            li {
              color: #ffe08b !important;
            }
          }
        }
        .noData {
          text-align: center;

          > img {
            margin-top: 146px;
          }
          > span {
            margin-top: 24px;
            padding-bottom: 146px;
            display: block;
            font-size: 16px;
            color: #b0beda;
          }
        }
      }
    }
  }
}
.leaderboard {
  background: url("../../assets/img/rank/Pic_Bg_JFB.png") no-repeat left top;
  background-size: 100% 100%;
  .ranking-list-cont-val {
    background: url("../../assets/img/rank/Pic_Text_JFB.png") no-repeat left top;
    background-size: 100% 100%;
  }
}
.learning {
  background: url("../../assets/img/rank/Pic_Bg_XXB.png") no-repeat left top;
  background-size: 100% 100%;
  .ranking-list-cont-val {
    background: url("../../assets/img/rank/Pic_XXB_Text.png") no-repeat left top;
    background-size: 100% 100%;
  }
}
.football {
  background: url("../../assets/img/rank/Pic_Bg_ZTB.png") no-repeat left top;
  background-size: 100% 100%;
  .ranking-list-cont-val {
    background: url("../../assets/img/rank/Pic_Text_ZTB.png") no-repeat left top;
    background-size: 100% 100%;
  }
}
.mvk {
  background: url("../../assets/img/rank/Pic_Bg_SFB.png") no-repeat left top;
  background-size: 100% 100%;
  .ranking-list-cont-val {
    background: url("../../assets/img/rank/Pic_Text_SFB.png") no-repeat left top;
    background-size: 100% 100%;
  }
}
.active {
  background: url("../../assets/img/rank/Pic_Bg_HYB.png") no-repeat left top;
  background-size: 100% 100%;
  .ranking-list-cont-val {
    background: url("../../assets/img/rank/Pic_Text_HYB.png") no-repeat left top;
    background-size: 100% 100%;
  }
}
.unAllow {
  cursor: not-allowed;
}
</style>